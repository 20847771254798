body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "ITC Avant Garde Gothic";
  src: local("ITC Avant Garde Gothic"),
   url("./assets/fonts/ITCAvantGardeStd-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans Bold";
  src: local("DM Sans Bold"),
   url("./assets/fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans Medium";
  src: local("DM Sans Medium"),
   url("./assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans Regular";
  src: local("DM Sans Regular"),
   url("./assets/fonts/DMSans-Regular.ttf") format("truetype");
}

.font-itc {
  font-family: "ITC Avant Garde Gothic";
}
.font-dm-bold {
  font-family: "DM Sans Bold";
}
.font-dm-medium {
  font-family: "DM Sans Medium";
}
.font-dm-regular {
  font-family: "DM Sans Regular";
}


.graytext {
  color: #575757;
}
