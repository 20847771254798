

.login-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: '100%';
 /*  border: 4px dotted blue; */
}

.login-image-wrapper {
  width: 50vmin;
  background: url('../../assets/images/loginback2.png');
  background-size: cover;
}

@media (max-width: 800px) {
  .login-image-wrapper {
    width: 15vmin;
  }


}


.login-header {
  font-size: 54px;
  margin-top: 2vw;
  text-align: left;
  margin-bottom: -10px;
}

.login-appname {
  font-size: 20px;
}

.login-form-wrapper {
/*    border: 4px dotted blue; */
   padding: 15px;
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form-content {
/*   border: 4px dotted blue;  */
}

.form-languageselector {
  /* border: 4px dotted blue;  */
  display: flex;
  flex: auto;
  align-items: center;
  margin-bottom: 2vmin;
}

.errorText {
  color: red;
  font-size: calc(1px + 2vmin);
}

.login-form {
/*   border: 4px dotted red;  */
}



.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
  background-color: #0C2FFA;
  border-radius: 32px;
  width: 10rem;
  height: 2.3rem;
}




.scan-wrapper {
  width: 300px;
  border: 4px dotted blue; 
}


#qrscanner__dashboard_section_swaplink {
  display: none !important;
}

img[alt*="Info"] {
  display: none !important;
}

#qrscanner {
  height: 100%;
}