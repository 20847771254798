.delivery-content {
  /* border: 4px dotted red; */
  margin-left: 15vw;
  margin-right: 15vw;
  padding-bottom: 3vh;
 }


.delivery-header {
 font-size: 3em;
 margin-top: 0.5em;
 text-align: left;
}



.deliveryheader-headeritems {
  display: flex;
  flex-direction: row;
  /* margin-left: auto; */
  /* border: 1px solid red; */
  /* width: 70%; */
  justify-content: flex-end;
}

.deliveryheader-headeritems .item {
  display: flex;
  flex-direction: row;
  margin-right: 1em;
  width: 8em;
/*    border: 1px solid #2B4BF2; 
 */   justify-content: flex-end;
}

.deliveryheader-headeritems .date {
  width: 12em;
}

.deliveryheader-headeritems .time {
  width: 6em;
}

.deliveryheader-headeritems .label {
  margin-left: 0.5vw;
  font-size: 20px;
  /* border: 1px solid red; */
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
}
.deliveryheader-headeritems .value {
  font-size: 30px;
  /* border: 1px solid red; */
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
}

.deliveryheader-headeritems .pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}



@media(max-width: 1000px) {

  .delivery-header {
    font-size: 2.0em;
   }

  .deliveryheader-header{
    width: 100%; 
  }

  .deliveryheader-inside {
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .deliveryheader-headeritems {
    margin-top: 5px;
    margin-left: 0;
    justify-content: space-between;
    width: 100%; 
  }

  .deliveryheader-headeritems .item {
    width:auto; 
  }

  .deliveryheader-headeritems .value {
    font-size: 15px;
  }

  .deliveryheader-headeritems .label {
    font-size: 10px;
  }
}



@media(max-width: 1000px) {
  .delivery-content {
   /*  border: 4px dotted blue; */
    margin-left: 3vw;
    margin-right: 3vw;
  }
}

.deliverybase {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 10px 15px 10px 15px;
/*   border-top: 1px solid rgba(80, 96, 108, 0.09);
  border-bottom: 1px solid rgba(80, 96, 108, 0.09); */
  background-color: #FAFAFA;
  border-radius: 32px;
}


.leftitems {
  display: flex;
  margin-right: auto;
}

.project-p-table {

}
.project-p-table p {
    display: flex;
    margin:0;
    width: 5vw;
    /* border: 1px solid red; */
    align-items: center;
    justify-content: center;
}

.project-p-table-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5vw;
    border-bottom: 1px solid rgba(80, 96, 108, 0.09);
}

.project-p-table-values {
    padding: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid rgba(80, 96, 108, 0.09);
    border-bottom: 1px solid rgba(80, 96, 108, 0.09);
}

.inList {
    background-color: #e6e6e6;
}

.table-first {
    /* border: 1px solid red; */
    justify-content: flex-start !important;
}

.project-p-table-footers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid rgba(80, 96, 108, 0.09);
    margin-bottom: 1.5rem;
}


.delivery-gray-area {
  background-color: #F1F0F0;
  border-radius: 5px;
  padding: 5px;
  margin-top: 1.5em;
}

.delivery-gray-area .item {
  display: flex;
  flex-direction: row;
}

.delivery-gray-area .item .label {
  width: 9rem;
}

.delivery-gray-area .item .value{
}

.delivery-gray-area .item .link{
  color: #0C2FFA;
  text-decoration: underline;
  cursor: pointer;
}

.delivery-times {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.delivery-times .item {
  display: flex;
  flex-direction: row;
}

.signatureBase {
  background-color: red;
}


.signature-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signature-item {
  margin: 1rem;
  padding: 10px;
/*   background-color: red; */
}

.signature-item .label{
  margin-bottom: 5px;
}

.signature-box {
  height: 6rem;
  width: 15rem;
  background: #F7F9FC;
  border: 1px dashed #2B4BF2;
  border-radius: 11px;
}

.signature-clearbtn {
  margin-top: 0.5rem;
  padding: 4px 20px;
  background: #2B4BF2;
  border-radius: 12px;
  color: white;
  border: 0;
  cursor: pointer;
}

.savebtn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.2rem 2rem;
  background: #000;
  border-radius: 12px;
  color: white;
  border: 0;
  cursor: pointer;
}

.logbtn {
  padding: 0.2rem 1.5rem;
  background: #000;
  border-radius: 12px;
  color: white;
  border: 0;
  cursor: pointer;
}


@media(max-width: 1000px) {
  .signature-container {
    flex-direction: column;
  }

  .project-p-table p {
    font-size: 9px;
}


}
