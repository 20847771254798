
.canvasModal {
/*     background-color: rgba(0, 0, 0, 0.157);
 */    max-width: 800px;
    margin: auto;
    margin-top: 5em
}

.canvasWrapper {
    background-color: rgb(255, 255, 255);
    border: 1px dashed black;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
 }

 .canvasBtns {
    margin-top: 1rem;
 }

 .canvasBtns .btn {
    margin-right: 1em;
 }