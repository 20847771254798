.homepage-content {
   /* border: 4px dotted red; */
   margin-left: 15vw;
   margin-right: 15vw;
   padding-bottom: 1vh;
  }


.homepage-header {
  font-size: 3em;
  margin-top: 0.5em;
  text-align: left;
}


@media(max-width: 1000px) {
  .homepage-content {
   /*  border: 4px dotted blue; */
    margin-left: 3vw;
    margin-right: 3vw;
  }
}

.classHeader {
  font-size: 16px;
  margin-top: 2rem;
  margin-bottom: 5px;
}



/* Data map item */
.deliverylist-item {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 10px 15px 10px 15px;
/*   border-top: 1px solid rgba(80, 96, 108, 0.09);
  border-bottom: 1px solid rgba(80, 96, 108, 0.09); */
  background-color: #FAFAFA;
  border-radius: 32px;
}

.deliverylist-inside {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.deliverylist-header{
/*   border: 1px solid green;
 */  width: 30%; 
}

.deliverylist-header .name{
  font-size: 20px;
  margin: 0;
/*     border: 1px solid blue; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deliverylist-header .factory{
  margin: 0;
}

/* Tilattu/Valmistettu/Toimitettu */
.deliverylist-headeritems {
  display: flex;
  flex-direction: row;
/*   margin-left: auto; */
/*   border: 1px solid red;
 */  width: 70%; 
  justify-content: flex-end;
}
.deliverylist-headeritems .item {
  display: flex;
  flex-direction: row;
  margin-right: 1em;
  width: 8em;
/*    border: 1px solid #2B4BF2; 
 */   justify-content: flex-end;
}

.deliverylist-headeritems .date {
  width: 12em;
}

.deliverylist-headeritems .time {
  width: 6em;
}

.deliverylist-headeritems .label {
  margin-left: 0.5vw;
  font-size: 20px;
  /* border: 1px solid red; */
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
}
.deliverylist-headeritems .value {
  font-size: 30px;
  /* border: 1px solid red; */
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
}

.deliverylist-headeritems .pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

@media(max-width: 1000px) {

  .deliverylist-header{
    width: 100%; 
  }

  .deliverylist-inside {
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .deliverylist-headeritems {
    margin-top: 5px;
    margin-left: 0;
    justify-content: space-between;
    width: 100%; 
  }

  .deliverylist-headeritems .item {
    width:auto; 
  }

  .deliverylist-headeritems .value {
    font-size: 15px;
  }

  .deliverylist-headeritems .label {
    font-size: 10px;
  }
}