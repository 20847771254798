@import 'antd/dist/antd.css';



.error404 {
  font-size: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header {
  position: relative;
/*   border: 4px dotted orange; */
  display: flex;
  flex-direction: row;
  margin-top: 3vmin;
  margin-left: 3vw;
  margin-right: 3vw;
  align-items: center;
  overflow: hidden;
}

@media(min-width: 900px) {
  .header {
    margin-left: 15vw;
    margin-right: 15vw;
    background-color: #fff;
  }
}

.headerlogo {
  width: 100px;

}




.header-btns {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.headerbtnlink {
  color: #333;
}


.headerprojects {
  margin-left: auto;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.headercontacts {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}

.logoutbtn {
  padding: 0.2rem 2rem;
  background: #000;
  border-radius: 12px;
  color: white;
  border: 0;
  cursor: pointer;
}


.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-dropdown {
  margin-left: auto;
    font-size: 20px;
    align-self: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* HAMBUREG MENU*/
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
} 

